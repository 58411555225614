"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.init = init;
const embedChatManager_1 = require("./embedChatManager");
// xuất hàm khởi tạo bong bóng chat
function init(p) {
    try {
        // khởi tạo sdk
        new embedChatManager_1.EmbedChatManager(p.page_id, p.config, p.is_debug).init();
    }
    catch (e) {
        // bắt lỗi
        console.error('BBH error:', e);
    }
}
